<template>
  <div class="home">
    <div class="content">
        <div class="container">
          <div class="logo_container">
            <span class="logo_helper"></span>
            <a href="https://www.abeychain.com/">
              <img src="../assets/logo.0ecbc0d8.png" width="10%" alt="AbeyChain.com" id="logo">
            </a>
          </div>
        </div>
      <!--        链接部分-->
      <div class="home-top">
        <div class="home-top-content">
          <div class="home-title">
            <span >ABEY Chain Test Faucet</span>
          </div>
          <div class="home-sub-title">
            <span>Automatically send 10 ABEY to your testnet account.<br>The ABEY can only be used for testnet.</span>
          </div>
<!--          <div style="text-align: center">-->
<!--            <a target="_blank" href="https://testnet.abeychain.com/" class="home-a">ABEY Test Chain,ChainID:179</a>-->
<!--          </div>-->
          <div class="home-get">
            <input v-model="address" class="home-get-input" placeholder="0x...">
            <button class="home-get-btn" @click="actionGetAbeyTest()">Submit</button>
          </div>
          <div v-show="showHash" class="home-hash">
            send hash: <a class="hash-a" target="_blank" :href="url">{{ txHash }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ('@/css/login.css')
export default {
  name: 'HelloWorld',
  data() {
    return {
      txHash: '0xd2058b8061334afe69815c88daf36097b97a8d9ee7b2ac0ece7aea439f426b82',
      showHash: false,
      address: '',
      url: ''
    }
  },
  props: {
    msg: String
  },
  methods: {
    //mouse 获取
   async actionGetAbeyTest() {
     if(this.address){
       let result = await this.$http.getBalance({address: this.address});
       if(result.code == 200){
         this.txHash = result.data.txHash;
         this.showHash = true
         this.url = `${process.env.VUE_APP_EXPLORER_URL}` + result.data.txHash;
       }
       console.log(result,'结果')
     }
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
body {

}

.container {
  margin-top: 30px;
  line-height: 23px;
  font-weight: 500;
  width: 100%;
  max-width: 100%;
  padding-right: 30px;
  padding-left: 50px;
}

.home {
  position: relative;
  width: 100%;
  height: 100vh;
  //background-color: #172029;
  color: white;
}

.content {
  height: 100%;
  //padding: 65px;
}

.home-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 15% 0 0 26%;

  .home-top-content {
    flex: 1;
  }
}

.home-a {
  font-size: 20px;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #37A2F0;
  line-height: 23px;
}

.home-hash {
  margin-top: 20px;
  font-size: 16px;
  font-weight: normal;
  line-height: 23px;
  color: #f5ca40;
}

.hash-a {
  color: #f5ca40;
}

.home-get {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;

  .home-get-text {
    padding-right: 7px;
  }

  .home-get-input {
    font-size: 16px;
    outline-style: none;
    border: 0;
    //color: white;
    width: 50%;
    height: 40px;
    //background: #172029;
    border: 1px solid #f5ca40;
    border-bottom-left-radius:6px;/*左下角圆角*/
    border-top-left-radius: 6px;/*左上角圆角*/
  }

  .home-get-btn {
    border: 0;
    color: white;
    //margin-left: 13px;
    width: 15%;
    height: 44px;
    background: #f5ca40;
    outline-style: none;
    border: 0;
    //border: 5px #0C6CB2 solid;
    border-right: 5px;
    cursor: pointer;
    border-bottom-right-radius:6px;/*左下角圆角*/
    border-top-right-radius: 6px;/*左上角圆角*/
  }
}

.home-line {
  margin-top: 50px;
  width: 100%;
  height: 1px;
  border-top: 1px #11ADB1 dashed;
}

.home-middle {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
}

.home-balance {
  padding-top: 33px;

  .home-balance-text {
    font-size: 20px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 23px;
  }

  .home-balance-num {
    font-family: Arial-BoldMT, Arial;
    color: #77C3FB;
    font-size: 20px;
  }
}
.home-title {
  font-size: 36px;
  font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #f5ca40;
  line-height: 23px;
}
.home-sub-title {
  margin-top: 20px;
  font-size: 16px;
  //font-family: Arial-BoldMT, Arial;
  font-weight: normal;
  color: #f5ca40;
  line-height: 23px;
}
.home-send {
  position: relative;
  flex: 1;
  padding-top: 32px;

  .home-send-text {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 20px;
  }

  .home-dropdown {
    font-family: Arial-ItalicMT, Arial;
    font-weight: normal;
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    height: 34px;
    background: #172029;
    border: 1px solid #2D7CB5;

    img {
      width: 10px;
      height: 10px;
      margin-right: 20px;
    }
  }
}

.home-dropdown-send {
  position: absolute;
  //right: 0;
  z-index: 999;
  width: 80%;
  border: 1px solid #2D7CB5;
  font-family: Arial-ItalicMT, Arial;
  font-weight: normal;
}

.home-select {
  //position: absolute;
  padding-left: 20px;
  height: 34px;
  line-height: 34px;
  background: #638AB0;
}

.home-drop {
  padding-left: 20px;
  height: 34px;
  line-height: 34px;
  background: #172029;
}

.home-address {
  z-index: 1;
  position: relative;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  padding-top: 31px;

  .home-address-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      cursor: pointer;
    }
  }

  .home-address-input {
    outline-style: none;
    border: 0;
    padding-left: 20px;
    padding-right: 20px;
    width: 80%;
    height: 34px;
    margin-top: 7px;
    color: white;
    background: #172029;
    border: 1px solid #f5ca40;
  }
}

.home-amount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 34px;
  margin-top: 7px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 20px;
  color: white;
  background: #172029;
  border: 1px solid #f5ca40;

  .home-amount-input {
    width: 97%;
    height: 30px;
    background-color: #172029;
    outline-style: none;
    border: 0;
    color: white;
  }

  .home-amount-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    img {
      width: 10px;
      height: 5px;
    }
  }
}

.home-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 75px;

  button {
    outline-style: none;
    border: 0;
    color: white;
    width: 40%;
    height: 46px;
    background: #f5ca40;
    border-radius: 5px;
    cursor: pointer;
  }
}

</style>
